const dateObj = new Date()
const startDay = new Date().setUTCHours(0, 0, 0, 0)
const endDay = new Date().setUTCHours(23, 59, 59, 999)
const yestStartDay = new Date(new Date().setDate(dateObj.getDate() - 1)).setUTCHours(0, 0, 0, 0)
const yestEndDay = new Date(new Date().setDate(dateObj.getDate() - 1)).setUTCHours(23, 59, 59, 999)
const lastWeekStartDay = new Date(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate() - 7).setUTCHours(0, 0, 0, 0)
const newDate = new Date()

const getQuarter = (d) => {
  d = d || new Date()
  var m = Math.floor(d.getMonth() / 3) + 2
  return m > 4 ? m - 4 : m
}

newDate.setDate(1)
newDate.setMonth(newDate.getMonth() - 1)

const lastMonthStartDay = new Date(newDate).setUTCHours(0, 0, 0, 0)
const lastMonthEndDay = new Date(new Date().setDate(0)).setUTCHours(23, 59, 59, 999)
const newDateQ = new Date()
newDateQ.setDate(1)
newDateQ.setMonth(newDateQ.getMonth() - getQuarter(new Date()))

const startQuarterDay = getQuarter(new Date()) >= 1 ? new Date(newDateQ).setUTCHours(0, 0, 0, 0) : new Date()
const endQuarterDay = getQuarter(new Date()) >= 1 ? lastMonthEndDay : new Date()
const newDateS = new Date()
newDateS.setDate(1)
newDateS.setMonth(newDateS.getMonth() - 6)
const lastSixMonthStart = new Date(newDateS).setUTCHours(0, 0, 0, 0)
const lastSixMonthEnd = lastMonthEndDay

const initialStartOfYear = new Date().setUTCFullYear(1000)
/**
 * @description Date values
 */
export const configDateObj = {
  startDay: new Date(startDay).toISOString(),
  endDay: new Date(endDay).toISOString(),
  yestStartDay: new Date(yestStartDay).toISOString(),
  yestEndDay: new Date(yestEndDay).toISOString(),
  lastWeekStartDay: new Date(lastWeekStartDay).toISOString(),
  lastWeekEndDay: new Date(endDay).toISOString(),
  lastMonthStartDay: new Date(lastMonthStartDay).toISOString(),
  lastMonthEndDay: new Date(lastMonthEndDay).toISOString(),
  startQuarterDay: new Date(startQuarterDay).toISOString(),
  endQuarterDay: new Date(endQuarterDay).toISOString(),
  lastSixMonthStart: new Date(lastSixMonthStart).toISOString(),
  lastSixMonthEnd: new Date(lastSixMonthEnd).toISOString(),
  initialStartOfYear: new Date(initialStartOfYear).toISOString()
}
