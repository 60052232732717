import React from 'react'
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import { swaggerDoc } from './swagger'


const ApiDocs = () => {

    return (
        <>
        <SwaggerUI spec={swaggerDoc} />
        </>

    )
}
export default ApiDocs