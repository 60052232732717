
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector'
import { CONSTANTS } from '../../constants'
import { configDateObj } from '../utils/date'
const {
  SEARCH_KEY,
  ENGINE_NAME,
  ENDPOINT_BASE
} = CONSTANTS

const connector = new AppSearchAPIConnector({
  searchKey: SEARCH_KEY,
  engineName: ENGINE_NAME,
  endpointBase: ENDPOINT_BASE
})
const {
  startDay,
  endDay,
  yestStartDay,
  yestEndDay,
  lastWeekStartDay,
  lastWeekEndDay,
  lastMonthStartDay,
  lastMonthEndDay,
  startQuarterDay,
  endQuarterDay,
  lastSixMonthStart,
  lastSixMonthEnd,
  initialStartOfYear
} = configDateObj
/**
 * @description Configuration
 */
export const configurationOptions = {
  apiConnector: connector,
  autocompleteQuery: {
    suggestions: {
      types: {
        documents: {
          fields: ['title', 'topics', 'locations', 'industries']
        }
      },
      size: 5
    }
  },
  initialState: {
    sortField: 'published_on',
    sortDirection: 'desc',
    resultsPerPage: 10,
  },
  searchQuery: {
    search_fields: {
      title: {},
      topics: {},
      locations: {},
      industries: {}
    },
    result_fields: {
      title: {
        snippet: {
          size: 1000,
          fallback: true
        }
      },
      url: {
        snippet: {
          fallback: true
        }
      },
      image_url: {
        raw: {},
        snippet: {
          fallback: true
        }
      },
      published_by: {
        snippet: {
          size: 100,
          fallback: true
        }
      },
      author_link: {
        snippet: {
          size: 100,
          fallback: true
        }
      },
      topics: {
        raw: {},
        snippet: {
          size: 100,
          fallback: true
        }
      },
      types: {
        raw: {},
        snippet: {
          size: 100,
          fallback: true
        }
      },
      published_on: {
        raw: {},
        snippet: {
          size: 100,
          fallback: true
        }
      },
      locations: {
        raw: {},
        snippet: {
          size: 100,
          fallback: true
        }
      },
      practice_groups: {
        raw: {},
        snippet: {
          size: 100,
          fallback: true
        }
      },
      industries: {
        raw: {},
        snippet: {
          size: 100,
          fallback: true
        }
      }
    },
    facets: {
      topics: {
        type: 'value', size: 100
      },
      published_by: {
        type: 'value', size: 100
      },
      locations: {
        type: 'value', size: 100
      },
      industries: {
        type: 'value', size: 100
      },
      practice_groups: {
        type: 'value', size: 100
      },
      published_on: {
        type: 'range',
        ranges: [
          { from: startDay, to: endDay, name: 'Today' },
          { from: yestStartDay, to: yestEndDay, name: 'Yesterday' },
          { from: lastWeekStartDay, to: lastWeekEndDay, name: 'Last Week' },
          { from: lastMonthStartDay, to: lastMonthEndDay, name: 'Last Month' },
          { from: startQuarterDay, to: endQuarterDay, name: 'Last Quarter' },
          { from: lastSixMonthStart, to: lastSixMonthEnd, name: 'Last 6 Months' },
          { from: initialStartOfYear, to: endDay, name: 'All' }
        ]
      }
    }

  },
  alwaysSearchOnInitialLoad: true
}
