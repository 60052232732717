import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser'
import './result.css'
import PropTypes from 'prop-types'
import marked from 'marked'

/**
 * @description Result card component to display results
 */
class ResultCard extends Component {
  render () {
    const { key, result } = this.props.props
    let date = ''
    if (result.published_on.raw) {
      const dateValue = result.published_on.raw
      date = new Date(dateValue)
    }
    
    let prevMetaDataFlag = false
    const publishedBy = result.published_by.snippet.split("&amp;").join(" & ")
    return (
      <>
        <div key={key} className="outerCard">
          <div className="listing-box">
            <div className="listing-box-header">
              <div className="listing-box-logo">
                <img alt="img" src={result.image_url.raw ? result.image_url.raw : require('../../..//assets/no-image.png') } onError={(e) => {
                  // e.target.src = '../../../assets/no-image.png'
                  e.target.style = 'visibility: hidden;' // inline styles in html format
                }} width="50"></img>
              </div>
              <div className="listing-box-title">
                <div className="author" title={publishedBy}>
                  <a href={result.author_link.snippet ? result.author_link.snippet : '#'} className = {result.author_link.snippet ? '' : 'disabled'}
                  target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-link"></i>{publishedBy}
                  </a>
                </div>
                <div className = {result.url.snippet ? 'listing-box-heading cursor' : 'listing-box-heading disabled'} onClick={(e) => {
                  e.preventDefault();
                  return window.open(
                    result.url.snippet,
                    '_blank' // <- This is what makes it open in a new window.
                  );
                }}>
                  <h2>{ReactHtmlParser(marked(result.title.snippet))}</h2>
                  <div className="listing-box-more">
                    <a href={result.url.snippet} target="_blank" rel="noopener noreferrer">
                      <span className={result.url.snippet ? "icon-arrow-right-circle" : "icon-arrow-right-circle grad-out disabled"}></span></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="listing-box-tags">

              {result.topics.raw  &&  result.topics.raw.length > 0 ? (
                <>
                  {prevMetaDataFlag = true}
                  <div className="tags-list">
                    <span className="icon-tag tag-list-icon" title="Topics"></span>
                    <div className="tags-listing">
                      {result.topics.raw !== null
                        ? (result.topics.raw.map((topic, index) => (
                          <span className="tags-listing-item" title={topic.trim()} key={index}>
                            {topic.trim()}{index + 1 === result.topics.raw.length ? '': ','}
                          </span>
                        ))) : ''}
                    </div>
                  </div>
                  </>
              ) : ''}
              {result.industries.raw  &&  result.industries.raw.length > 0 ? (
                <>
                {prevMetaDataFlag === true && (
                    <div className="divider-tags"></div>
                  )}
                { prevMetaDataFlag = true }
                <div className="tags-list">
                  <span className="tag-list-icon" title="Industries"><i className="fa fa-industry"></i></span>
                  <div className="tags-listing">
                    {result.industries.raw !== null
                      ? (result.industries.raw.map((industry, index) => (
                        <span className="tags-listing-item" key={index} title={industry.trim()}>
                          {industry.trim()}{index + 1 === result.industries.raw.length ? '': ','}
                        </span>
                      ))) : ''}
                  </div>
                </div>
                </>
              ) : ''}
              {result.practice_groups.raw  &&  result.practice_groups.raw.length > 0 ? (
                <>
                {prevMetaDataFlag === true && (
                    <div className="divider-tags"></div>
                  )}
                { prevMetaDataFlag = true }
                <div className="tags-list">
                  <span className="tag-list-icon" title="Practice Groups"><i className="fa fa-object-group"></i></span>
                  <div className="tags-listing">
                    {result.practice_groups.raw !== null
                      ? (result.practice_groups.raw.map((pGroup, index) => (
                        <span className="tags-listing-item" key={index} title={pGroup.trim()}>
                          {pGroup.trim()}{index + 1 === result.practice_groups.raw.length ? '': ','}
                        </span>
                      ))) : ''}
                  </div>
                </div>
                </>
              ) : ''}

            </div>
              
            <div className="listing-footer">
              {/* <div className="publish">Type&nbsp;:&nbsp; <strong>{result.type.snippet}</strong></div>
              <div className="divider-tags"></div> */}
              <div className="location">Locations&nbsp;:&nbsp;
                <strong>
                  {result.locations.raw !== null
                    ? (result.locations.raw.map((location, index) => (
                      <span key={index}> {location}{result.locations.raw.length !== index + 1 && ' , ' }</span>
                    ))) : ''}
                </strong>
              </div>
              <div className="divider-tags"></div>
              <div className="publish">Published Date&nbsp;:&nbsp; <strong>{date ? 
              date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() : ''}</strong></div>
            </div>
            <div className="listing-footer footer-last">
              {/* <div className="publish">Type: <strong>{result.type.snippet}</strong></div> */}
              

              {
                result.types.raw && result.types.raw.length > 0 ? (
                  <>
                  <div className="location">
                  Type(s)&nbsp;:&nbsp;
                  <strong>
                  {
                    result.types.raw !== null
                    ? (result.types.raw.map((type, index) => (
                      <span key={index}> {type}{result.types.raw.length !== index + 1 && ' , ' }</span>
                    ))) : ''}
                </strong>
                </div>
                  </>
                ):
                ''
              }
            </div>

          </div>
        </div>
      </>
    )
  }
}
/** ResultCard component prop types */
ResultCard.propTypes = {
  props: PropTypes.object

}

export default ResultCard
