import React from 'react'
import ReactDOM from 'react-dom'
import 'typeface-lato'
import 'font-awesome/css/font-awesome.css'
import 'simple-line-icons/css/simple-line-icons.css'
import App from './App'
import { unregister } from './registerServiceWorker'

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)
unregister()
