import React from 'react'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import './App.css'
import Main from './app/containers/main'
import ApiDocs from './api-docs'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import history from './history'
export default function App () {
  return (
    <Router history={history}>
      <Switch>
            <Route exact path="/" component={Main} />
            <Route exact path="/swagger-ui" component={ApiDocs} />
            <Route render={() => <Redirect to="/" />} />
      </Switch>

    </Router>
  )
}

