
import React, { Component } from 'react'
import MainTemplate from '../components/main/main'
/**
 * @description Main container component
 */
class Main extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loadData: false,
      loader: true,
      error: false,
      windowDimensions: { width: 0, height: 0 }
    }
  }

  /** @desc When component will setup */
  componentDidMount () {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions);
  }
  /**
   * @description When component will un-mount
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  /**
   * @description Update window dimensions
   */
  updateDimensions = () => {
    this.setState({ windowDimensions: {width: window.innerWidth, height: window.innerHeight }});
  }
  render () {
    const { windowDimensions } = this.state
    return (
        <>
          <MainTemplate windowDimensions={windowDimensions}/>
        </> 

    )
  }
}
export default Main
