export const swaggerDoc = {
    "openapi": "3.0.0",
    "info": {
      "title": "Knowledge Hub",
      "version": "1.0.0"
    },
    "servers": [
      {
        "url": "https://appsearch.mindpeer.com/api/as/v1/engines/search-dev"
      }
    ],
    "components": {
      "securitySchemes": {
        "bearerAuth": {
          "type": "http",
          "scheme": "bearer"
        }
      }
    },
    "tags": [
      {
        "name": "search",
        "description": "Search Section"
      },
    ],
    "paths": {
      "/search": {
        "post": {
          "tags": [
            "search"
          ],
          "consumes": [
            "application/json"
          ],
          "produces": [
            "application/json"
          ],
          "summary": "Search results",
          "description": '# Search results with the query and filters. \n ### The search key will need to set in the top Authorize button for processing the search operations',
          "security": [
            {
              "bearerAuth": []
            }
          ],
          "requestBody": {
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "example": {
                    "query": "",
                    "facets": {
                        "topics": {
                            "type": "value",
                            "size": 100
                          },
                          "published_by": {
                            "type": "value",
                            "size": 100
                          },
                          "locations": {
                            "type": "value",
                            "size": 100
                          },
                          "industries": {
                            "type": "value",
                            "size": 100
                          },
                          "practice_groups": {
                            "type": "value",
                            "size": 100
                          },
                          "published_on": {
                            "type": "range",
                            "ranges": [
                              {
                                "from": "2022-04-14T00:00:00.000Z",
                                "to": "2022-04-14T23:59:59.999Z",
                                "name": "Today"
                              },
                              {
                                "from": "2022-04-13T00:00:00.000Z",
                                "to": "2022-04-13T23:59:59.999Z",
                                "name": "Yesterday"
                              },
                              {
                                "from": "2022-04-06T00:00:00.000Z",
                                "to": "2022-04-14T23:59:59.999Z",
                                "name": "Last Week"
                              },
                              {
                                "from": "2022-03-01T00:00:00.000Z",
                                "to": "2022-03-31T23:59:59.999Z",
                                "name": "Last Month"
                              },
                              {
                                "from": "2022-01-01T00:00:00.000Z",
                                "to": "2022-03-31T23:59:59.999Z",
                                "name": "Last Quarter"
                              },
                              {
                                "from": "2021-10-01T00:00:00.000Z",
                                "to": "2022-03-31T23:59:59.999Z",
                                "name": "Last 6 Months"
                              },
                              {
                                "from": "1000-04-14T11:37:53.128Z",
                                "to": "2022-04-14T23:59:59.999Z",
                                "name": "All"
                              }
                            ]
                          },
                    },
                    "search_fields": {
                        "title": {},
                        "topics": {},
                        "locations": {},
                        "industries": {}
                    },
                    "result_fields": {
                        "title": {
                            "snippet": {
                              "size": 1000,
                              "fallback": true
                            }
                          },
                          "url": {
                            "snippet": {
                              "fallback": true
                            }
                          },
                          "image_url": {
                            "raw": {},
                            "snippet": {
                              "fallback": true
                            }
                          },
                          "published_by": {
                            "snippet": {
                              "size": 100,
                              "fallback": true
                            }
                          },
                          "author_link": {
                            "snippet": {
                              "size": 100,
                              "fallback": true
                            }
                          },
                          "topics": {
                            "raw": {},
                            "snippet": {
                              "size": 100,
                              "fallback": true
                            }
                          },
                          "types": {
                            "raw": {},
                            "snippet": {
                              "size": 100,
                              "fallback": true
                            }
                          },
                          "published_on": {
                            "raw": {},
                            "snippet": {
                              "size": 100,
                              "fallback": true
                            }
                          },
                          "locations": {
                            "raw": {},
                            "snippet": {
                              "size": 100,
                              "fallback": true
                            }
                          },
                          "practice_groups": {
                            "raw": {},
                            "snippet": {
                              "size": 100,
                              "fallback": true
                            }
                          },
                          "industries": {
                            "raw": {},
                            "snippet": {
                              "size": 100,
                              "fallback": true
                            }
                          }
                    },
                    "filters": {},
                    "sort": {
                      "published_on": "desc"
                    },
                    "page": {
                      "size": 10,
                      "current": 1
                    }
                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Successful Response",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "meta": {
                        "type": "object"
                      },
                      "results": {
                        "type": "object",
                      }
                    }
                  }
                }
              }
            },
            "401": {
              "description": "Unauthorized",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            },
            "400": {
              "description": "Bad Request",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            },
            "500": {
              "description": "Internal Server Error",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            }
          }
        }
      },
      "/query_suggestion": {
        "post": {
          "tags": [
            "query_suggestion"
          ],
          "consumes": [
            "application/json"
          ],
          "produces": [
            "application/json"
          ],
          "summary": "Search query suggestions",
          "description": '# Search auto complete query suggestions. \n ### The search key will need to set in the top Authorize button for processing the search operations',
          "security": [
            {
              "bearerAuth": []
            }
          ],
          "requestBody": {
            "content": {
              "application/json": {
                "schema": {
                  "type": "object",
                  "example": {
                    "query": "covid",
                    "types": {
                      "documents": {
                        "fields": [
                          "title",
                          "topics",
                          "locations",
                          "industries"
                        ]
                      }
                    },
                    "size": 5
                  }
                }
              }
            }
          },
          "responses": {
            "200": {
              "description": "Successful Response",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "meta": {
                        "type": "object"
                      },
                      "results": {
                        "type": "object",
                      }
                    }
                  }
                }
              }
            },
            "401": {
              "description": "Unauthorized",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            },
            "400": {
              "description": "Bad Request",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            },
            "500": {
              "description": "Internal Server Error",
              "content": {
                "application/json": {
                  "schema": {
                    "type": "object",
                    "properties": {
                      "error": {
                        "type": "string"
                      },
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }